var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Change-Record"}},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getOrePoolList),expression:"getOrePoolList"}],ref:"Change-Record-list",staticClass:"demo-infinite-container",attrs:{"id":"Change-Record-List","infinite-scroll-disabled":_vm.list.busy,"infinite-scroll-distance":_vm.list.pagination.page_size}},[_c('a-list',{attrs:{"data-source":_vm.list.item,"locale":_vm.$language.zhCN},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{ref:`list-row-` + index},[_c('a-row',{class:`list-item-body list-item-body-${index}`,attrs:{"gutter":[0, 10]}},[_c('a-col',{attrs:{"span":24}},[_c('p',[_vm._v(_vm._s(item.day))])]),_c('a-col',{style:({ display: 'flex' }),attrs:{"span":24}},[_c('div',[_c('span',[_vm._v(_vm._s(item.currency)+"  ")]),_c('span',[_vm._v(" "+_vm._s(item.title)+"   "),(item.status)?_c('span',{style:({
                    color:
                      item.status === '已完成'
                        ? 'green'
                        : item.status === '拒绝'
                        ? 'red'
                        : 'orange',
                  })},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()])]),_c('div',[_c('p',{style:({
                  color:
                    item.content[0] === '-'
                      ? 'green'
                      : item.content[0] === '+'
                      ? 'red'
                      : 'white',
                })},[_vm._v(" "+_vm._s(item.content)+" ")])])]),(item.remark)?_c('a-col',{attrs:{"span":24}},[_c('div',[_vm._v("操作说明")]),_c('div',[_vm._v(_vm._s(item.remark))])]):_vm._e()],1)],1)}}])},[(_vm.list.loading && !_vm.list.busy)?_c('div',{staticClass:"demo-loading-container"},[_c('a-spin')],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }