
<template>
  <div id="Change-Record">
    <div
      id="Change-Record-List"
      ref="Change-Record-list"
      v-infinite-scroll="getOrePoolList"
      class="demo-infinite-container"
      :infinite-scroll-disabled="list.busy"
      :infinite-scroll-distance="list.pagination.page_size"
    >
      <a-list :data-source="list.item" :locale="$language.zhCN">
        <a-list-item
          slot="renderItem"
          slot-scope="item, index"
          :ref="`list-row-` + index"
        >
          <a-row
            :class="`list-item-body list-item-body-${index}`"
            :gutter="[0, 10]"
          >
            <a-col :span="24">
              <p>{{ item.day }}</p>
            </a-col>
            <a-col :span="24" :style="{ display: 'flex' }">
              <div>
                <span>{{ item.currency }}&nbsp;&nbsp;</span>
                <span>
                  {{ item.title }}&nbsp;&nbsp;
                  <span
                    v-if="item.status"
                    :style="{
                      color:
                        item.status === '已完成'
                          ? 'green'
                          : item.status === '拒绝'
                          ? 'red'
                          : 'orange',
                    }"
                  >
                    {{ item.status }}
                  </span>
                </span>
              </div>
              <div>
                <p
                  :style="{
                    color:
                      item.content[0] === '-'
                        ? 'green'
                        : item.content[0] === '+'
                        ? 'red'
                        : 'white',
                  }"
                >
                  {{ item.content }}
                </p>
              </div>
            </a-col>
            <a-col :span="24" v-if="item.remark">
              <div>操作说明</div>
              <div>{{ item.remark }}</div>
            </a-col>
          </a-row>
        </a-list-item>
        <div v-if="list.loading && !list.busy" class="demo-loading-container">
          <a-spin />
        </div>
      </a-list>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { List, Pagination } from "ant-design-vue";
Vue.use(List);
Vue.use(Pagination);

import infiniteScroll from "vue-infinite-scroll";

export default {
  directives: {
    infiniteScroll,
  },
  // 数据
  data() {
    return {
      list: {
        item: [],
        loading: false,
        busy: false,
        pagination: {
          page_index: 1,
          page_size: 10,
        },
        rollingDirection: "down",
      },
    };
  },
  // 方法
  methods: {
    async getOrePoolList() {
      const a = this.list.pagination;
      // 总数不存在的时候，获取第一页
      if (!a.total) a.page_index = 1;
      else if (a.total / a.page_index <= a.page_size) {
        this.list.busy = true;
        return true;
      } else {
        a.page_index++;
      }

      this.list.loading = true;
      a.currency = this.$route.params.type;
      await this.$axios
        .get("personal-center/wallet-details", {
          params: {
            ...a,
          },
        })
        .then((res) => {
          this.list.loading = false;
          // console.log(res);
          if (res.code === 0) {
            this.list.item.push(...res.data.details);
            this.list.pagination = res.data.pagination;
            const a = res.data.pagination;
            this.list.busy = a.total / a.page_index <= a.page_size;
          } else {
            console.log(res.message);
            this.$message.console.error(res.message);
          }
        });
    },
  },
};
</script>

<style lang="less">
#Change-Record {
  .ant-list-items {
    > li.ant-list-item {
      > .ant-row {
        width: 100%;

        > .ant-col {
          &:nth-child(2) {
            > div:last-child {
              text-align: right;
            }
          }

          &:nth-child(3) {
            display: flex;

            > div {
              &:first-child {
                flex-shrink: 0;
                margin-right: 1em;

                &::after {
                  margin-left: 0.1em;
                  content: ":";
                }
              }

              &:last-child {
                flex-shrink: 1;
                width: 100%;
              }
            }
          }
        }
      }

      // height: 10rem;
    }
  }
}
</style>
